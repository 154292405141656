import type { SearchRefinementAttributeValue } from '~/graphql/generated/uacapi/type-document-node'
import styles from './TeamRefinementValue.module.scss'
import Image from '~/components/primitives/Image'

interface TeamRefinementValueProps {
	value: SearchRefinementAttributeValue
}
const TeamRefinementValue = ({ value }: TeamRefinementValueProps) => {
	return (
		<div className={styles['labeled-teamchip-container']} data-selected={value.selected}>
			{value?.description && (
				<Image src={value?.description} width={30} height={30} alt={value.label} className={styles['team-icon']} />
			)}
			<div>{value.label}</div>
		</div>
	)
}

export default TeamRefinementValue
