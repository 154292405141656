import React, { useCallback } from 'react'
import Select, { Option } from '~/components/primitives/Select/Select'
import { SIZE_MAP } from '~/lib/size-like-mine'
import { updateUrlParams } from '~/lib/utils'
import { useFormatMessage } from '../hooks/useFormatMessage'
import useNavigation from '../hooks/useNavigation'

interface SizeSelectorProps {
	className?: string
	selectId: string
	availableSizes: string[]
	allSizes?: string[]
	viewPreference?: string
	defaultMessageKey?: string
	labelMessageKey?: string
}

export default function SizeSelector({
	className = '',
	selectId,
	availableSizes,
	viewPreference,
	allSizes = Object.keys(SIZE_MAP),
	defaultMessageKey = 'size-like-mine',
	labelMessageKey = 'size-like-mine',
}: SizeSelectorProps) {
	const formatMessage = useFormatMessage()
	const router = useNavigation()

	const handleSizeChange = useCallback(
		(event: React.ChangeEvent<HTMLButtonElement>) => {
			const viewPreference = event.target.value === 'default' ? null : event.target.value
			const nextUrl = updateUrlParams(router.asPath, { viewPreference, start: null })
			router.replace(nextUrl)
		},
		[router],
	)

	return (
		<div className={`form-field ${className}`.trim()}>
			{labelMessageKey && <label htmlFor={selectId}>{formatMessage(labelMessageKey)}</label>}
			<Select id={selectId} value={viewPreference || 'default'} onChange={handleSizeChange}>
				<Option key="default" value="default">
					{formatMessage(defaultMessageKey)}
				</Option>

				{allSizes.map((option) => (
					<Option key={option} value={option} disabled={!availableSizes?.includes(option)}>
						{formatMessage('athlete-wearing', { size: option })}
					</Option>
				))}
			</Select>
		</div>
	)
}
