import clsx from 'clsx'
import React, { memo, useCallback } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { CaretIcon } from '~/components/primitives/icons'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import Select, { Option } from '~/components/primitives/Select/Select'
import styles from './Pagination.module.scss'
import useNavigation from '~/components/hooks/useNavigation'

interface PaginationProps {
	/** URL used for the "Previous" button to redirect the user to the previous page relative to their current page */
	previousUrl?: string
	/** URL used for the "Next" button to redirect the user to the next page relative to their current page */
	nextUrl?: string
	/** The page number of the product listing the user is currently being displayed */
	currentPage: number
	/** The total number of pages in the specific user-selected product listing */
	totalPages: number

	includeNoScriptLinks: boolean
	/** Object that contains the url's and page numbers for all possible product listing pages that can be displayed to the user */
	pageMap: {
		pageNumber: number
		url: string
	}[]
}

export default memo(function Pagination(props: PaginationProps) {
	const formatMessage = useFormatMessage()
	const router = useNavigation()

	/**
	 * Handles the case where the user is navigating to a new page by using the page
	 * selector
	 */
	const onPageSelectorChanged = useCallback(
		(evt: React.ChangeEvent<HTMLButtonElement>) => {
			const newUrl = props.pageMap.find((p) => parseInt(evt.target.value, 10) === p.pageNumber)?.url
			if (newUrl) {
				router.push(newUrl)
			}
		},
		[props.pageMap, router],
	)

	if (!props.nextUrl && !props.previousUrl) {
		// RETURN NOTHING IF THERE ARE NO OTHER PAGES
		return <></>
	}

	return (
		<>
			{props.includeNoScriptLinks && (
				<noscript>
					<ul>
						{props.pageMap.map((p) => (
							<li key={`page-${p.pageNumber}`}>
								<a href={p.url}>{p.pageNumber}</a>
							</li>
						))}
					</ul>
				</noscript>
			)}
			<nav aria-label={formatMessage('pagination')} className={styles.pager}>
				<div>
					{props.previousUrl && (
						<div className={clsx(styles['nav-control-item'], styles['nav-control-item__left'])}>
							<CaretIcon data-caret-icon size="SM" />
							<LocaleLink
								aria-label={formatMessage('pager-previous-aria')}
								data-testid="pager-previous"
								href={props.previousUrl}
								className={'link_underline'}
							>
								{formatMessage('pager-previous')}
							</LocaleLink>
						</div>
					)}
				</div>
				<div>
					<label className={styles['visually-hidden']} htmlFor={'page-selector'}>
						{formatMessage('pager-of-label', { totalPages: props.totalPages })}
					</label>
					<Select
						id={'page-selector'}
						data-testid={'page-selector'}
						className={styles.selector}
						value={props.currentPage.toString()}
						onChange={onPageSelectorChanged}
					>
						{props.pageMap.map((page) => {
							return (
								<Option
									aria-label={formatMessage('page', { page: page.pageNumber })}
									aria-current={props.currentPage === page.pageNumber && 'page'}
									key={`product-page-${page.pageNumber}`}
								>
									{page.pageNumber.toString()}
								</Option>
							)
						})}
					</Select>
					<span className={styles['total-count']}>{formatMessage('pager-of', { totalPages: props.totalPages })}</span>
				</div>
				<div>
					{props.nextUrl && (
						<div className={clsx(styles['nav-control-item'], styles['nav-control-item__right'])}>
							<LocaleLink
								aria-label={formatMessage('pager-next-aria')}
								data-testid="pager-next"
								href={props.nextUrl}
								className={'link_underline'}
							>
								{formatMessage('pager-next')}
							</LocaleLink>
							<CaretIcon data-caret-icon size="SM" />
						</div>
					)}
				</div>
			</nav>
		</>
	)
})
