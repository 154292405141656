import styles from './BrowserHeader.module.scss'
import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

export function BrowserHeaderSkeleton() {
	return (
		<div className={`${styles.header} ${styles['header-skeleton']}`}>
			<div className={styles['title-skeleton']}>
				{/* Is Breadcrumbs for CLPs, "Search Results" label for Search PLPs */}
				<UaSkeleton variant="rectangular" width={120} height={20} cornerRadius={2} />

				<UaSkeleton
					variant="rectangular"
					className={styles['query-string-skeleton']}
					width={184}
					height={32}
					cornerRadius={2}
				/>
			</div>

			<div className={styles['actionBar-skeleton']}>
				<UaSkeleton variant="rectangular" className={styles['text-body']} width={220} height={34} cornerRadius={2} />

				<div className={styles['filterSort-skeleton']}>
					<UaSkeleton variant="rectangular" className={styles['text-body']} width={95} height={18} cornerRadius={2} />
					<UaSkeleton
						variant="rectangular"
						className={styles['product-sort']}
						width={110}
						height={44}
						cornerRadius={2}
					/>
				</div>
			</div>
		</div>
	)
}
