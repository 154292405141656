import clsx from 'clsx'
import Select, { Option } from '~/components/primitives/Select/Select'

import React, { useCallback, useEffect, useState } from 'react'

import type { SearchSortingOption } from '~/graphql/generated/uacapi/type-document-node'
import { useFormatMessage } from '../hooks/useFormatMessage'

export interface SortingProps {
	className?: string
	sortOptions: SearchSortingOption[]
	onSortingUpdate: (sortBy: string) => void
	defaultSortRule?: string
}

const Sorting = ({ className, sortOptions, onSortingUpdate, defaultSortRule }: SortingProps) => {
	// An invalid sort can be passed in as default
	const validDefaultSort = sortOptions.find((option) => option.id === defaultSortRule)
	const defaultSorting = validDefaultSort ? validDefaultSort.id : sortOptions[0]?.id
	const [sortBy, setSortBy] = useState(defaultSorting)
	const formatMessage = useFormatMessage()

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLButtonElement>) => {
			setSortBy(event.target.value)
			onSortingUpdate(String(event.target.value))
		},
		[onSortingUpdate],
	)

	// Resetting sort rule on new category loads
	useEffect(() => {
		setSortBy(defaultSorting)
	}, [defaultSorting])

	return (
		<div className={clsx('form-field', className)}>
			<label htmlFor="product-list-sorting">{formatMessage('sort')}</label>
			<Select id="product-list-sorting" data-testid="product-list-sorting" value={sortBy} onChange={handleChange}>
				{sortOptions?.map((option) => (
					<Option key={option.id} value={option.id}>
						{option.label}
					</Option>
				))}
			</Select>
		</div>
	)
}

export default Sorting
