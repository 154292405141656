import type { SearchRefinementAttributeValue } from '~/graphql/generated/uacapi/type-document-node'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'

interface DiscountRefinementValueProps {
	value: SearchRefinementAttributeValue
}

const DiscountRefinementValue = ({ value }: DiscountRefinementValueProps) => {
	const formatMessage = useFormatMessage()

	return (
		<>
			{formatMessage(`refinement-discountpercentage-${value.label}`)} ({value.hitCount})
		</>
	)
}

export default DiscountRefinementValue
