'use client'

import { memo } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'

export interface BrowserTitleProps {
	className: string
	isCategory: boolean
	title: string
}

export default memo(function BrowserTitle({ className, isCategory, title }: BrowserTitleProps) {
	const formatMessage = useFormatMessage()

	return (
		<div className={className}>
			{!isCategory && <div className="text-body font-semibold">{formatMessage('search-results')}</div>}
			<h1 className="text-h4">{isCategory ? title : `"${title}"`}</h1>
		</div>
	)
})
