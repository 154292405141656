import styles from './CategoryList.module.scss'
import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

const CategoryListSkeleton = () => {
	return (
		<section id="category-list-skeleton" className={`${styles['category-list-skeleton']}`} style={{ marginBottom: 40 }}>
			<h2 id="category-list-title-skeleton">
				<UaSkeleton variant="rectangular" width={160} height={30} cornerRadius={2} />
			</h2>

			<ul className={styles['list-skeleton']}>
				{Array(6)
					.fill(1)
					.map((id, index) => (
						<li key={`li-skeleton-${index}`}>
							<UaSkeleton variant="rectangular" width={95} height={20} cornerRadius={2} />
						</li>
					))}
			</ul>
		</section>
	)
}

export default CategoryListSkeleton
