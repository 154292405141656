import { memo, useCallback, useId, useRef } from 'react'
import { useIsomorphicLayoutEffect } from '~/lib/staticHookHandlers'

import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type {
	SearchRefinementAttribute,
	SearchRefinementAttributeValue,
} from '~/graphql/generated/uacapi/type-document-node'
import { createRefinementUrl, getRefinementKey } from '~/lib/routes'
import { ColorRefinementValue } from './ColorRefinementValue'
import styles from './RefinementValues.module.scss'
import { Button } from '@ua-digital-commerce/ua-web-components/components/Button'
import TeamRefinementValue from '~/components/shared/ProductBrowser/ProductRefinements/components/RefinementValues/TeamRefinementValue/TeamRefinementValue'
import { FACET_MAP } from '~/lib/search'
import { useAnalytics } from '~/components/hooks/useAnalytics'
import PriceRefinementValue from '~/components/shared/ProductBrowser/ProductRefinements/components/RefinementValues/PriceRefinementValue/PriceRefinementValue'
import CheckIcon from '~/components/primitives/icons/CheckIcon'
import DiscountRefinementValue from '~/components/shared/ProductBrowser/ProductRefinements/components/RefinementValues/DiscountRefinementValue/DiscountRefinementValue'

interface RefinementValuesProps {
	pathWithoutQuery: string
	queryParams: URLSearchParams
	onRefinementClick?: (event, value) => boolean
	refinements: SearchRefinementAttribute[]
	refinement: SearchRefinementAttribute
	categoryId: string
}

const getSubsetCount = (refinement: SearchRefinementAttribute): number => {
	switch (refinement.attributeId) {
		// Show all options in these cases
		case 'c_enduse':
		case 'c_colorgroup':
		case 'price':
			return refinement.values.length
		// Sizes are displayed two in a row
		// So we show 20 instead of the deafult 10
		case 'c_size':
			return 20
		// Most options show a subset of 10
		default:
			return 10
	}
}

const RefinementValues = ({
	refinements,
	refinement,
	pathWithoutQuery,
	categoryId,
	onRefinementClick,
	queryParams,
}: RefinementValuesProps) => {
	const locale = useLocale()
	const formatMessage = useFormatMessage()

	/* -------------------- Show More Logic -------------------- */
	const listId = useId()
	const list = useRef({} as HTMLUListElement)
	const maxItems = getSubsetCount(refinement)

	// Hide overflowing items _only if JavaScript is enabled_
	useIsomorphicLayoutEffect(() => {
		const childrenArr = Array.from(list.current.children)
		childrenArr.slice(0, maxItems).forEach((item) => {
			item.removeAttribute('hidden')
		})

		// is any "overflow" (past maxItems) refinement selected - return right away and do not move on to hiding "overflow" refinements
		const expandedRefinement = refinement.values.find((refi, index) => refi.selected && index > maxItems)
		if (expandedRefinement || refinement.values.length <= maxItems) return

		const items = childrenArr.slice(maxItems)
		items.forEach((item) => item.setAttribute('hidden', ''))

		const toggleButton = document.querySelector(`button[aria-controls="${list.current.id}"]`) as HTMLButtonElement
		if (toggleButton) {
			toggleButton.setAttribute('aria-expanded', String(false))
			toggleButton.textContent = formatMessage(`show-more-refinements`)
			toggleButton.removeAttribute('hidden')
		}
	}, [refinement.values])

	function handleToggleClick(event: React.MouseEvent<HTMLButtonElement>): void {
		const button = event.currentTarget
		const oldAttrValue = button.getAttribute('aria-expanded') === String(true)

		// Update Button A11y
		const expanded = !oldAttrValue
		button.setAttribute('aria-expanded', String(expanded))
		button.textContent = formatMessage(`show-${expanded ? 'less' : 'more'}-refinements`)

		// Update List Item Visibility
		const items = Array.from(list.current.children).slice(maxItems) as HTMLLIElement[]
		items.forEach((item) => (expanded ? item.removeAttribute('hidden') : item.setAttribute('hidden', '')))
	}

	const { analyticsManager } = useAnalytics()

	const handleRefinementClick = useCallback(
		(label: string, refinementName: string) => {
			analyticsManager.applyNextPageData({
				refinement_type: refinementName,
				refinement_attribute: label,
				page_finding_method: 'Navigation',
			})
		},
		[analyticsManager],
	)

	const getRefinementValue = useCallback(
		(value: SearchRefinementAttributeValue) => {
			if (refinement.attributeId === FACET_MAP.colorGroup.uacapiId) {
				return <ColorRefinementValue value={value} />
			}
			if (refinement.attributeId === FACET_MAP.team.uacapiId) {
				return <TeamRefinementValue value={value} />
			}
			if (refinement.attributeId === FACET_MAP.salePriceLow.uacapiId) {
				return <PriceRefinementValue value={value} />
			}
			if (refinement.attributeId === 'c_discountPercentage') {
				return <DiscountRefinementValue value={value} />
			}
			return value.label
		},
		[refinement.attributeId],
	)

	return (
		<>
			<ul
				ref={list}
				id={listId}
				className={styles['refinement-values']}
				data-type={refinement.attributeId}
				data-testid="refinement-values"
			>
				{refinement.values.map((value) => (
					/*
					 * NOTE:
					 * There has been a bit of back and forth between using the next/link vs. an <a> tag. The current
					 * approved approach is to use next/link. This has passed QA for regression and uses the passHref
					 * prop in order to surface the <a> tag from next/link for SEO purposes.
					 */
					<li key={value.label} className="text-body">
						<LocaleLink
							className={refinement.attributeId === 'price' ? 'bfx-price' : undefined}
							title={formatMessage('refinements-value-title', {
								refinement: refinement.label.toLocaleLowerCase(),
								value: value.label,
							}).toString()}
							onClick={(e) => {
								handleRefinementClick(value.label, refinement.label)
								return onRefinementClick?.(e, {
									...value,
									attributeId: refinement.attributeId,
								})
							}}
							href={createRefinementUrl({
								refinements,
								url: pathWithoutQuery,
								currentQueryParams: queryParams.toString(),
								categoryId,
								updatedRefinements: { [getRefinementKey(refinement.attributeId, value.label)]: !value.selected },
								locale,
							})}
							data-selected={value.selected}
							passHref
						>
							<span>
								<CheckIcon size="SM" />
							</span>
							{getRefinementValue(value)}
						</LocaleLink>
					</li>
				))}
			</ul>

			{refinement.values.length > maxItems && (
				<Button text aria-expanded={false} aria-controls={listId} hidden onClick={handleToggleClick}>
					{formatMessage('show-more-refinements')}
				</Button>
			)}
		</>
	)
}

export default memo(RefinementValues)
