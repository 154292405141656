'use client'

import { memo } from 'react'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import styles from './FilterChip.module.scss'
import clsx from 'clsx'
import Image from '~/components/primitives/Image'
import { CloseIcon } from '~/components/primitives/icons'

interface FilterChipProps {
	label: string
	title?: string
	href: string
	showIcon?: boolean
	onClick?: (e: React.SyntheticEvent<HTMLElement>) => void
	logoUrl?: string
}

function FilterChip({ label, title, href, showIcon = true, onClick, logoUrl }: FilterChipProps) {
	return (
		<LocaleLink
			className={clsx('text-subtext', styles.filterChip, showIcon && styles.icon)}
			href={href}
			title={title || label}
			onClick={onClick}
		>
			{logoUrl && <Image src={logoUrl} width={20} height={20} alt={label} />}
			{label}
			<CloseIcon size="SM" />
		</LocaleLink>
	)
}

export default memo(FilterChip)
