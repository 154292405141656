import React, { useMemo } from 'react'
import type { Category, InputMaybe, SearchRefinementInput } from '~/graphql/generated/uacapi/type-document-node'
import type { PageContentData } from '~/lib/types/coremedia.interface'

import type { Optional } from 'types/strict-null-helpers'

import Pagination from '~/components/primitives/Pagination/Pagination'
import { buildGridWithContent, computeGridBreakpointsFromContent, isProductData } from '~/lib/content'
import { NUMBER_PRODUCTS_PER_PAGE, getPaginationData } from '~/lib/products'
import type { ClientProductData, ClientProductList } from '~/lib/types/product.interface'
import styles from './ProductGrid.module.scss'
import { useLocale } from '~/components/hooks/useLocale'
import ContentSlot from '~/components/cms/ContentSlot/ContentSlot'
import { joinPathAndSearchParams } from '~/lib/utils'
import { ProductTile } from '../ProductTile/ProductTile'
import { useDebugComponent } from '~/components/hooks/useDebugComponent'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { useMediaQuery } from '~/components/hooks/useMediaQuery'
import mediaQueries from '~/lib/client-only/media-queries'
import { isCoreMediaType } from '~/lib/cms/legacy/type-guard'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { ContentSource } from '~/lib/client-server/cms/types'

export interface ProductGridProps {
	// The search refinement input used for the initial product search.
	//  Is re-used when refetching products when auth token changes.
	searchRefinementInput?: InputMaybe<SearchRefinementInput> | undefined

	// The intial set of products to display
	products: ClientProductList

	// The category in which this product list will be displayed
	category?: Category

	// If this was a search, this is the query string that was used.
	searchQuery?: string

	// This is the content associated with this grid.
	pageContent?: PageContentData

	// The offset on which to start the product list.  This is used
	//	to determine how to allow the user to navigate to next/previous
	//	pages.
	offset: number

	// These are parameters that can be associated with a product grid.
	//	They include things like custom refinements, and special sizing.
	queryParams: URLSearchParams

	// This is the full url where the user is browsing this grid.  This is
	//	necessary to be able to render the pagination properly.
	pathWithoutQuery: string

	// A "zombie" product tile will visually appear as a UaSkeleton component
	// to the user but be populated with server-generated product data to
	// satisfy SEO bots.
	showZombieTile?: boolean
	id?: string
}

const findExtendedSizeFilters = (query: URLSearchParams) => {
	const entries = Array.from(query.entries())
	const lengthPref = entries.find((entry) => entry[1] === 'length')
	const lengthPrefNumber = lengthPref && lengthPref[0]?.split('prefn')[1]
	const lengthPrefKey = entries.find((entry) => entry[0] === `prefv${lengthPrefNumber}`)
	const lengthPrefValue = lengthPrefKey && lengthPrefKey[1]
	return lengthPrefValue
}

/**
 * The ProductGrid is the part of the product listing pages that includes only the products and in-grid
 * content.  This list is usually contained within the ProductBrowser but shouldn't need to be.  The
 * browser provides additional functionality like refinement selection, sorting, etc.
 * @returns
 */
export default function ProductGrid({
	id,
	products,
	category,
	offset,
	pageContent,
	pathWithoutQuery,
	queryParams,
	showZombieTile,
}: ProductGridProps) {
	useDebugComponent('ProductGrid')
	const locale = useLocale()
	const { isMobile } = useLayoutMeasurement()
	const isDesktop = useMediaQuery(mediaQueries.desktopUp)

	/**
	 * Retrieves the pagination map that includes previous, next and all the pages that can be
	 * navigated to from this grid.
	 */
	const pagingData = useMemo(
		() =>
			getPaginationData(
				offset,
				products.totalCount,
				joinPathAndSearchParams(pathWithoutQuery, queryParams),
				NUMBER_PRODUCTS_PER_PAGE,
			),
		[products.totalCount, offset, pathWithoutQuery, queryParams],
	)

	const extendedSizeFilters = findExtendedSizeFilters(queryParams) as string

	// Get the names of all selected color values
	const faceOutColors: Optional<string[]> = useMemo(() => {
		const colorGroup = products.refinementAttributes.find((refinement) => refinement.attributeId === 'c_colorgroup')
		const selectedColorLabels = colorGroup?.values?.filter((value) => value.selected).map((value) => value.label) ?? []
		return selectedColorLabels
	}, [products.refinementAttributes])

	const gridData = buildGridWithContent(pageContent, products.products, isMobile, offset, NUMBER_PRODUCTS_PER_PAGE)

	const { isEnhancedPLPenabled } = useFeatureFlags()

	const slotComponents = useMemo(() => {
		return gridData.gridItems.map((slot, index) => {
			let slotComponent: React.ReactNode

			if (isProductData(slot)) {
				// Because these tiles are memoized, we need to add a unique identifier to force
				// the tiles to rebuild on the page when data changes. We can use the promotion
				// ID to add uniqueness to the IDs. This covers the cases: price changes on new
				// auth status, promo is added/removed but price remains the same, etc.
				const camelCasePromoId = slot?.productPromotions?.[0]?.promotionId?.replace(/\s/g, '-') ?? 'FPP'
				const id = `product-${slot.id}-${camelCasePromoId}`

				slotComponent = (
					<ProductTile
						id={id}
						key={id}
						isZombie={showZombieTile}
						product={slot as ClientProductData}
						faceOutColors={faceOutColors}
						extendedSizeFilters={extendedSizeFilters}
						wishListEnabled
						showQuickAddButton
						isExperimentalView={!!isEnhancedPLPenabled}
						hasImagePriorityAsHigh={index === 0}
						category={category}
						viewPreference={queryParams.get('viewPrerefence') ?? undefined}
						teamFilters={queryParams.get('teamFilters')?.split('|') || []}
					/>
				)
			} else if (isCoreMediaType(slot)) {
				const id = `content-${slot.id}`
				const breaks = computeGridBreakpointsFromContent(slot)
				slotComponent = (
					<div id={id} key={id} className={styles['product-content']} data-col={isDesktop ? breaks.md : breaks.xs}>
						<ContentSlot
							placementId={slot.id}
							defaultModule={{ source: ContentSource.COREMEDIA, data: slot }}
							locale={locale}
							index={index}
						/>
					</div>
				)
			}

			return slotComponent || null
		})
	}, [
		gridData.gridItems,
		showZombieTile,
		faceOutColors,
		extendedSizeFilters,
		isEnhancedPLPenabled,
		category,
		queryParams,
		isDesktop,
		locale,
	])

	const bottomContent = useMemo(() => {
		if (gridData.bottomContent?.[0]?.uaFilteredItems.length === 0) return null

		return (
			<div className={styles['product-list-content']}>
				{gridData.bottomContent?.[0]?.uaFilteredItems?.map((item) => (
					<ContentSlot
						key={item.id}
						placementId={'category-grid-bottom'}
						defaultModule={{ source: ContentSource.COREMEDIA, data: item }}
						locale={locale}
						index={-1}
					/>
				))}
			</div>
		)
	}, [gridData.bottomContent, locale])

	return (
		<section className={`${styles['product-list-container']}`} id={id}>
			<div className={`products_list ${styles['product-listing']}`}>{slotComponents}</div>

			<Pagination {...pagingData} includeNoScriptLinks={true} />

			{bottomContent}
		</section>
	)
}
