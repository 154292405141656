/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react'
import { UaConfigPublicImpl } from '~/lib/client-only/ua-config'

type ComponentDebugInfo = {
	name: string
	renderCount: number
	lastUpdate: Date
}

const _globalDebugInfo: Record<string, ComponentDebugInfo> = {}

/**
 * Keep track of component-specific debug data to help with understanding
 * the performnance of the app.
 * @param name The name of the component.  This should be unique across
 * the app.
 * @returns
 */
export function useDebugComponent(name: string): ComponentDebugInfo {
	if (UaConfigPublicImpl.isDevelopment && !_globalDebugInfo[name]) {
		_globalDebugInfo[name] = {
			name,
			renderCount: 0,
			lastUpdate: new Date(),
		}
	}

	useEffect(function intentionallyRunOnEveryRender() {
		if (UaConfigPublicImpl.isDevelopment) {
			_globalDebugInfo[name].renderCount += 1
			_globalDebugInfo[name].lastUpdate = new Date()
		}
	})

	return _globalDebugInfo[name]
}

export function useDebugComponentInfo(): Record<string, ComponentDebugInfo> {
	return _globalDebugInfo
}
