import styles from './ProductRefinements.module.scss'
import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

export function ProductRefinementsSkeleton() {
	return (
		<div
			id="product-refinements"
			className={`${styles['product-refinements']} ${styles['product-refinements-skeleton']}`}
		>
			<section
				id="product-refinements-filters-skeleton"
				className={`${styles['product-refinements__filters']}`}
				aria-labelledby="refinements-title"
			>
				<ul className={styles['product-refinements__filter-options']}>
					{Array(10)
						.fill(1)
						.map((slot, index) => (
							<li key={index}>
								<div className={styles['details-skeleton']}>
									<div className={styles['summary-skeleton']}>
										<UaSkeleton variant="rectangular" width={90} height={20} cornerRadius={2} />
										<UaSkeleton variant="circular" width={20} height={20} cornerRadius={2} />
									</div>
								</div>
							</li>
						))}
				</ul>
			</section>
		</div>
	)
}
