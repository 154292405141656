'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { parsedUrlQueryToUrlSearchParams, updateQueryParams } from '~/lib/utils'

import { useLocale } from '~/components/hooks/useLocale'
import { type BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import ProductGrid from '~/components/shared/ProductGrid/ProductGrid'
import SizeSelector from '~/components/shared/SizeSelector'
import type {
	Category,
	InputMaybe,
	SearchRefinementAttribute,
	SearchRefinementInput,
	SearchSortingOption,
} from '~/graphql/generated/uacapi/type-document-node'
import { SIZE_MAP } from '~/lib/size-like-mine'
import type { PageContentData } from '~/lib/types/coremedia.interface'
import type { ClientProductList } from '~/lib/types/product.interface'
import BackToTopButton from './BackToTopButton/BackToTopButton'
import styles from './ProductBrowser.module.scss'
import { ProductRefinements } from './ProductRefinements/ProductRefinements'
import { getHeading } from '~/lib/plp-seo'
import { useRouter, usePathname } from 'next/navigation'
import { excludeSegmentParametersFromQueryParameters, type SearchProductsQuery } from '~/lib/products'
import { ProductManager } from '~/components/providers/ProductProvider/ProductProvider'
import { useDebugComponent } from '~/components/hooks/useDebugComponent'
import { canonicalizeUrlFromPageTypeWithCombinedParams, type CanonicalizePageType } from '~/lib/client-only/canonicals'
import { cacheDataToProps } from '~/lib/server/caching'
import type { CacheData } from '~/lib/client-server/caching'
import BrowserHeader, { PLP_HEADER_ID } from './BrowserHeader/BrowserHeader'
import ContentSlot from '~/components/cms/ContentSlot/ContentSlot'
import { getGuidedShoppingModule } from '~/lib/content'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { ContentSource } from '~/lib/client-server/cms/types'

export interface ProductBrowserProps {
	cachedData?: CacheData
	searchRefinementInput?: InputMaybe<SearchRefinementInput> | undefined
	isLoadingClient?: boolean
	allRefinements: SearchRefinementAttribute[]
	sortOptions: SearchSortingOption[]
	productList: ClientProductList
	getProductsRequest?: (searchContext) =>
		| Promise<{
				products: ClientProductList | null
				breadcrumbs: BreadcrumbTrail
		  }>
		| Promise<
				| {
						products: ClientProductList
						breadcrumbs: BreadcrumbTrail
				  }
				| undefined
		  >
	category?: Category
	pageContent?: PageContentData
	breadcrumbs: BreadcrumbTrail
	pageQueryParameters: SearchProductsQuery
	pageType: CanonicalizePageType
}

export default function ProductBrowser({
	cachedData,
	searchRefinementInput,
	isLoadingClient = false,
	productList,
	category,
	pageContent,
	getProductsRequest,
	breadcrumbs,
	pageQueryParameters,
	pageType,
}: ProductBrowserProps) {
	useDebugComponent('ProductBrowser')

	const router = useRouter()
	const pathname = usePathname()
	const locale = useLocale()
	const { isDesktop } = useLayoutMeasurement()

	const nav = useRef({} as HTMLElement)
	const productBrowser = useRef<HTMLDivElement>(null)
	const backToTopButton = useRef({} as HTMLButtonElement)
	const isSearch = !category
	const refinementUrl = `/${locale}${isSearch ? '/search/' : category.url}`
	const validSizeOptions = useMemo(() => {
		const sizeTypes = ProductManager.getSizeMapFromRefinements(productList?.refinementAttributes)
		if (sizeTypes) {
			return Object.values(sizeTypes)
		}
		return Object.keys(SIZE_MAP)
	}, [productList?.refinementAttributes])

	const title = getHeading(pageQueryParameters, searchRefinementInput?.customRefinements || [], category)
	const guidedShopping = useMemo(() => pageContent && getGuidedShoppingModule(pageContent), [pageContent])

	const queryPramsWithoutDynamicParams = useMemo(
		() => parsedUrlQueryToUrlSearchParams(excludeSegmentParametersFromQueryParameters(pageQueryParameters)),
		[pageQueryParameters],
	)

	const queryParamsWithDynamicParams = useMemo(
		() => parsedUrlQueryToUrlSearchParams(pageQueryParameters),
		[pageQueryParameters],
	)

	const canonicalPathName = useMemo(
		() => canonicalizeUrlFromPageTypeWithCombinedParams(pageType, queryParamsWithDynamicParams),
		[pageType, queryParamsWithDynamicParams],
	)

	const handleSortingUpdate = useCallback(
		(sortRule: string) => {
			const newParams = updateQueryParams(queryPramsWithoutDynamicParams, { srule: sortRule, start: null })
			router.push(`${pathname}?${newParams.toString()}`)
		},
		[pathname, queryPramsWithoutDynamicParams, router],
	)

	/**
	 * Toggles the Refinements Nav open and closed for mobile screens
	 */
	const [isOpen, setIsOpen] = useState(false)
	const handleToggleMobileNav = (): void => {
		setIsOpen((old) => !old)
	}
	const handleCloseMobileNav = (): void => {
		setIsOpen(false)
	}

	useEffect(() => {
		if (isDesktop && isOpen) {
			handleCloseMobileNav()
		}
		// depends on isDesktop only
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop])

	// Respond to how the user scrolls the page
	useEffect(() => {
		const backToTopButtonRef = backToTopButton.current
		const event = 'onscrollend' in window ? 'scrollend' : 'scroll'
		window.addEventListener(event, handleWindowScroll, { passive: true })
		return () => window.removeEventListener(event, handleWindowScroll)

		function handleWindowScroll(): void {
			// Reveal `BackToTopButton` if the user has scrolled too far down
			const revealBackToTopButton =
				window.scrollY > 1800 ||
				(window.scrollY > 0.3 * document.body.scrollHeight && document.body.scrollHeight > 3700)

			backToTopButtonRef.hidden = !revealBackToTopButton
		}
	}, [])

	const availableSizes = () => {
		const sizeArray = productList.products.flatMap((product) =>
			product.shopTheLookColors?.flatMap((color) => color.images?.flatMap((img) => img?.modelSize)),
		)
		const sizes = new Set(sizeArray)

		return validSizeOptions.filter((size) => sizes.has(size))
	}

	useEffect(() => {
		let observer: IntersectionObserver
		if (isDesktop) {
			observer = new IntersectionObserver(
				([entry]) => {
					// When user scrolls down on the screen, expand the left side nav so we don't leave empty space underneath
					const classnameToExpand = 'extended'
					nav.current?.classList?.toggle(styles[classnameToExpand], entry.intersectionRatio < 0.5)
				},
				{
					rootMargin: `-97px 0px 0px 0px`,
					threshold: [0, 0.5, 1.0],
				},
			)

			const headerElement = document.querySelector(`#${PLP_HEADER_ID}`)
			if (headerElement) observer.observe(headerElement)
		}

		return () => {
			observer?.disconnect()
		}
	}, [isDesktop])

	return (
		<>
			<div className={styles.container} ref={productBrowser} {...(cachedData ? cacheDataToProps(cachedData) : {})}>
				<BrowserHeader
					productList={productList}
					pathWithoutQuery={pathname}
					refinementUrl={refinementUrl}
					queryParams={queryPramsWithoutDynamicParams}
					category={category}
					breadcrumbs={breadcrumbs}
					title={title}
					handleToggleMobileNav={handleToggleMobileNav}
					onSortingUpdate={handleSortingUpdate}
				/>

				{guidedShopping && (
					<ContentSlot
						placementId={'guided-shopping'}
						defaultModule={{ source: ContentSource.COREMEDIA, data: guidedShopping }}
						locale={locale}
						index={0}
						className={styles['guided-shopping']}
					/>
				)}

				{/* TODO: Accessibly label `nav` element and _optionally_ `aside` element */}
				<aside>
					<nav ref={nav}>
						<ProductRefinements
							availableSizes={availableSizes()}
							allSizes={validSizeOptions}
							itemCount={productList.totalCount}
							category={category}
							searchRefinementsRequest={getProductsRequest}
							refinements={productList.refinementAttributes}
							pathWithoutQuery={refinementUrl}
							sortOptions={productList.sortingOptions}
							sortRule={pageQueryParameters.srule}
							pageQueryParameters={pageQueryParameters}
							isDialogOpen={isOpen}
							onCloseDialog={handleCloseMobileNav}
							onSortingUpdate={handleSortingUpdate}
						/>
					</nav>
				</aside>

				{category?.showFitModelSelection && (
					<div className={styles['size-selector']}>
						<SizeSelector
							availableSizes={availableSizes()}
							allSizes={validSizeOptions}
							selectId="plp-selector"
							viewPreference={pageQueryParameters.viewPreference ?? undefined}
						/>
					</div>
				)}

				<ProductGrid
					showZombieTile={isLoadingClient}
					pathWithoutQuery={canonicalPathName.split('?')[0]}
					queryParams={queryPramsWithoutDynamicParams}
					searchRefinementInput={searchRefinementInput}
					products={productList}
					category={category}
					pageContent={pageContent}
					offset={+(pageQueryParameters.start || 0)}
				/>
			</div>

			<BackToTopButton ref={backToTopButton} hidden />
		</>
	)
}
