import styles from './ProductBrowser.module.scss'
import { ProductGridSkeleton } from '../ProductGrid/ProductGridSkeleton'
import { ProductRefinementsSkeleton } from './ProductRefinements/ProductRefinementsSkeleton'
import CategoryListSkeleton from './ProductRefinements/components/CategoryList/CategoryListSkeleton'
import { BrowserHeaderSkeleton } from './BrowserHeader/BrowserHeaderSkeleton'

export interface ProductBrowserSkeletonInterface {
	isSearch?: boolean
}

export function ProductBrowserSkeleton({ isSearch = true }: ProductBrowserSkeletonInterface) {
	return (
		<div className={styles.container}>
			<BrowserHeaderSkeleton />

			<aside>
				{!isSearch && <CategoryListSkeleton />}
				<ProductRefinementsSkeleton />
			</aside>

			<ProductGridSkeleton />
		</div>
	)
}
