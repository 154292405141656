import { useMemo } from 'react'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type { Category } from '~/graphql/generated/uacapi/type-document-node'
import styles from './CategoryList.module.scss'

interface CategoryListProps {
	category: Category
}

const CategoryList = ({ category }: CategoryListProps) => {
	const uniqueCategories = category?.parentCategoryTree?.filter((cat) => cat.id !== category.id)
	const categoryName = uniqueCategories?.at(-1)?.name ?? category.name

	/*
	 * Assign the URL for the category list's top-level parent title. If no parent exists,
	 * default to the category's alternativeURL/URL.
	 */
	const categoryUrl = useMemo(() => {
		const categoryParent = category.parentCategoryTree?.find((x) => x.name === category.name)
		return categoryParent?.alternativeUrl ?? categoryParent?.url ?? category.alternativeUrl ?? category.url
	}, [category])

	const categoryItemLinks = useMemo(() => {
		const categories = category?.categories ?? []
		return categories
			.filter((c) => !c.hideFromLeftNavigationRefinement)
			.map((c) => (
				<li key={c.id}>
					<LocaleLink
						className="text-body font-semibold"
						href={c.alternativeUrl ?? (c.url as string)}
						aria-current={c.id === category.id}
					>
						{c.name}
					</LocaleLink>
				</li>
			))
	}, [category?.categories, category.id])

	return (
		<section
			id="category-list"
			className={styles['category-list']}
			aria-labelledby="category-list-title"
			data-testid="category-list"
			data-list-hidden={category.hideSubCategoriesInCategoryMenu}
		>
			<h2 id="category-list-title">
				<LocaleLink href={categoryUrl as string} className="text-h6 font-semibold">
					{categoryName}
				</LocaleLink>
			</h2>

			{!category.hideSubCategoriesInCategoryMenu && <ul>{categoryItemLinks}</ul>}
		</section>
	)
}

export default CategoryList
