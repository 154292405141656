import { memo, useCallback, useMemo, useState } from 'react'
import type {
	Category,
	SearchRefinementAttribute,
	SearchSortingOption,
} from '~/graphql/generated/uacapi/type-document-node'

import { ensureString } from 'types/strict-null-helpers'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import SizeSelector from '~/components/shared/SizeSelector'
import { createRefinementUrl, createSortUrl, getRefinementKey } from '~/lib/routes'
import { extractSearchParamsFromDynamicParameters, parsedUrlQueryToUrlSearchParams, updateUrlParams } from '~/lib/utils'
import styles from './ProductRefinements.module.scss'
import { CategoryList, RefinementValues } from './components'
import {
	urlSearchParamsToSearchProductsQuery,
	type SearchProductsQuery,
	getSelectedRefinementValues,
} from '~/lib/products'
import { ButtonLink } from '@ua-digital-commerce/ua-web-components/components/ButtonLink'
import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import { LoadingDots } from '~/components/primitives/LoadingDots/LoadingDots'
import type { ClientProductList, SearchRefinementAttributeValueWithAttributeId } from '~/lib/types/product.interface'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import RefinementBar from '../RefinementBar/RefinementBar'
import Dialog from '~/components/primitives/Dialog/Dialog'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'

interface ProductRefinementsProps {
	availableSizes?: string[]
	allSizes?: string[]
	itemCount: number
	category?: Category
	refinements: SearchRefinementAttribute[]
	searchRefinementsRequest?: (searchContext) =>
		| Promise<{
				products: ClientProductList | null
				breadcrumbs: BreadcrumbTrail
		  }>
		| Promise<
				| {
						products: ClientProductList
						breadcrumbs: BreadcrumbTrail
				  }
				| undefined
		  >
	pathWithoutQuery: string
	sortOptions: SearchSortingOption[]
	sortRule?: string
	pageQueryParameters: SearchProductsQuery
	isDialogOpen?: boolean
	onCloseDialog?: () => void
	onSortingUpdate: (sortBy: string) => void
	onUpdate?: () => void
}

export const REFINEMENTS_ID = 'product-refinements-filters'
export const MOBILE_FILTER_BUTTON_ID = 'mobile-filter-button'

export const ProductRefinements = memo(function ProductRefinements({
	availableSizes,
	allSizes,
	itemCount: initialItemCount,
	category,
	refinements: initialRefinements,
	searchRefinementsRequest,
	pathWithoutQuery,
	sortOptions,
	sortRule: initialSortRule,
	pageQueryParameters,
	isDialogOpen,
	onCloseDialog,
	onSortingUpdate,
	onUpdate,
}: ProductRefinementsProps) {
	const formatMessage = useFormatMessage()
	const locale = useLocale()
	const { isMobile } = useLayoutMeasurement()

	const [itemCount, setItemCount] = useState(initialItemCount)
	const [sortRule, setSortRule] = useState(initialSortRule)
	const [customRefinements, setCustomRefinements] = useState<SearchRefinementAttribute[] | undefined>(undefined)
	const [isRefinementsLoading, setRefinementsLoading] = useState(false)
	const [refinementChanges, setRefinementChanges] = useState<Record<string, boolean>>({})

	const refinements = useMemo(() => customRefinements || initialRefinements, [customRefinements, initialRefinements])
	// This value is similar but distinct from the populated refinements variable in
	// the `BrowserHeader` component. This component keeps state from user actions whereas
	// `BrowserHeader` only uses refinements populated via the current URL.
	const populatedRefinements: SearchRefinementAttribute[] = useMemo(
		() => refinements.filter((refinement) => refinement.values.filter((value) => value.hitCount > 0)),
		[refinements],
	)
	const selectedRefinementValues = useMemo(
		() => getSelectedRefinementValues(populatedRefinements),
		[populatedRefinements],
	)
	const hasActiveFilters = useMemo(() => selectedRefinementValues?.length > 0, [selectedRefinementValues?.length])
	const hasActiveSortRule = useMemo(
		() => initialSortRule !== undefined || sortRule !== initialSortRule,
		[sortRule, initialSortRule],
	)

	const queryParamsAsUrlSearchParams = useMemo(() => {
		const query = parsedUrlQueryToUrlSearchParams(pageQueryParameters)
		const srule = query.get('srule')
		if (sortRule)
			if (srule === sortOptions[0].id) {
				query.delete('srule')
			} else {
				query.set('srule', sortRule)
			}
		return query
	}, [pageQueryParameters, sortOptions, sortRule])

	const [applyHref, setApplyHref] = useState<string>(
		createRefinementUrl({
			refinements,
			url: `${pathWithoutQuery}`,
			categoryId: ensureString(category?.id),
			updatedRefinements: {},
			locale,
			currentQueryParams: queryParamsAsUrlSearchParams.toString(),
		}),
	)

	// Generates context object for search new refinements
	const getChangedContextData = useCallback((searchUrl: string): SearchProductsQuery => {
		const newUrlArr = searchUrl.split('?')
		const searchParamsStr = newUrlArr[1] || ''
		const searchParams = urlSearchParamsToSearchProductsQuery(
			extractSearchParamsFromDynamicParameters({ searchParams: searchParamsStr }),
		)

		const cid = newUrlArr[0].split('/').slice(3).filter(Boolean)
		const searchContext = { cid, ...searchParams }

		return searchContext
	}, [])

	// On mobile prevents redirection on new url but updates refinements instead
	const onRefinementClick = useCallback(
		(event: React.SyntheticEvent<HTMLElement>, changedValue: SearchRefinementAttributeValueWithAttributeId) => {
			onUpdate?.()
			if (isMobile) {
				event.preventDefault()
				if (isRefinementsLoading) {
					// prevents changing refinements during loading new data
					return false
				}
				let updatedRefinements = {}
				if (changedValue) {
					const priceValues = refinements.find((refinement) => refinement.attributeId === 'price')?.values
					const prevPrice = priceValues?.find((value) => value.label in refinementChanges)
					const changes = { ...refinementChanges }
					// clear prev price if we have new one and prev
					if (priceValues?.some((value) => value.label === changedValue.label) && !!prevPrice) {
						delete changes[prevPrice.label]
					}
					updatedRefinements = {
						...changes,
						[getRefinementKey(changedValue.attributeId, changedValue.label)]: !changedValue.selected,
					}
				}
				// format of newUrl is "/en-us/c/mens/military_tactical/?prefn1=size&prefv1=XS%7CS"
				const newUrl = createRefinementUrl({
					refinements,
					url: `${pathWithoutQuery}`,
					categoryId: ensureString(category?.id),
					updatedRefinements,
					locale,
					currentQueryParams: queryParamsAsUrlSearchParams.toString(),
				})
				const searchContext = getChangedContextData(newUrl)
				setRefinementsLoading(true)
				searchRefinementsRequest?.(searchContext)
					.then((data) => {
						setItemCount(data?.products?.totalCount || initialItemCount)
						setCustomRefinements(data?.products?.refinementAttributes || initialRefinements)
						setApplyHref(newUrl)
						setRefinementChanges(updatedRefinements)
					})
					.finally(() => {
						setRefinementsLoading(false)
					})
			}
			return !isMobile
		},
		[
			onUpdate,
			isMobile,
			isRefinementsLoading,
			refinements,
			pathWithoutQuery,
			category?.id,
			locale,
			queryParamsAsUrlSearchParams,
			getChangedContextData,
			searchRefinementsRequest,
			initialItemCount,
			initialRefinements,
			refinementChanges,
		],
	)

	// Handler for removing all filters on PLP
	const handleResetMobileFilter = useCallback(() => {
		setRefinementChanges({})
		setItemCount(initialItemCount)
		setCustomRefinements(initialRefinements)
		setSortRule(initialSortRule)
	}, [initialItemCount, initialRefinements, initialSortRule])

	// Handler for removing a filter selected in the filter menu, but not yet applied to the URL
	const handleRemoveSelectedFilter = (
		e?: React.SyntheticEvent<HTMLElement, Event>,
		refinement?: SearchRefinementAttributeValueWithAttributeId,
	) => {
		if (e && refinement) {
			return onRefinementClick(e, refinement)
		}
		return false
	}

	/* NOTE: This sorting list should be using the <Sorting> component so we don't have two  different sorting components on the same page */
	const refinementOptions = useMemo(
		() =>
			sortOptions.map(({ id, label }) => {
				const href = createSortUrl(applyHref, id)
				return (
					<li key={id}>
						<LocaleLink
							href={href}
							onClick={(e) => {
								e.preventDefault()
								if (isMobile) {
									setSortRule(id)
									setApplyHref(href)
									return false
								}
								onSortingUpdate(id)
								return true
							}}
							aria-current={sortRule ? id === sortRule : id === 'now-trending'}
							data-appearance="radio"
						>
							{label}
						</LocaleLink>
					</li>
				)
			}),
		[applyHref, isMobile, onSortingUpdate, sortOptions, sortRule],
	)

	// Handler for closing and resetting all filters on PLP
	const handleClearFilters = useCallback(() => {
		onCloseDialog?.()
		handleResetMobileFilter()
	}, [handleResetMobileFilter, onCloseDialog])

	const renderRefinementContent = (isForDialog?: boolean) => {
		return (
			<section
				id={REFINEMENTS_ID}
				className={`${styles['product-refinements__filters']}`}
				aria-labelledby="refinements-title"
				data-testid={`${REFINEMENTS_ID}${isForDialog ? '-dialog' : ''}`}
			>
				<div className={styles['product-refinements__mobile-menu-header']}>
					{!isForDialog && (
						<div className={styles['product-refinements__title']}>
							<h2 id="refinements-title" className="text-h6 font-semibold">
								{formatMessage('refinements-title')}
							</h2>

							<button
								type="button"
								id={MOBILE_FILTER_BUTTON_ID}
								aria-label={formatMessage('close')}
								onClick={handleResetMobileFilter}
								aria-controls="mobile-filters-container"
								aria-expanded
							/>
						</div>
					)}

					{hasActiveFilters ? (
						<RefinementBar
							className={styles['product-refinements__selected-filters']}
							allRefinements={refinements}
							selectedRefinements={selectedRefinementValues}
							refinementUrl={`${pathWithoutQuery}`}
							queryParams={queryParamsAsUrlSearchParams}
							category={category}
							showClear={false}
							onUpdate={handleRemoveSelectedFilter}
						/>
					) : undefined}
				</div>

				{/* TODO: For Screen Readers, are we certain users will know they're adding filters here? */}
				<ul className={styles['product-refinements__filter-options']} data-testid="product-refinements__filter-options">
					<li>
						<details open={hasActiveSortRule}>
							<summary>
								<h3 className="text-body font-semibold">{formatMessage('sort')}</h3>
								<ArrowIcon size="SM" />
							</summary>

							<ul className={styles['product-refinements__sort-list']}>{refinementOptions}</ul>
						</details>
					</li>

					{populatedRefinements.map((refinement) => {
						const selectedCount = refinement.values.filter((v) => v.selected).length
						return (
							<li key={refinement.attributeId}>
								<details open={selectedCount !== 0}>
									<summary>
										<h3 className="text-body font-semibold" data-testid="refinement-option">
											{`${refinement.label}${selectedCount ? ` (${selectedCount})` : ''}`}
										</h3>
										<ArrowIcon size="SM" />
									</summary>

									<RefinementValues
										refinements={refinements}
										refinement={refinement}
										onRefinementClick={onRefinementClick}
										pathWithoutQuery={pathWithoutQuery}
										queryParams={queryParamsAsUrlSearchParams}
										categoryId={ensureString(category?.id)}
									/>
								</details>
							</li>
						)
					})}
				</ul>
			</section>
		)
	}

	return (
		<>
			<div id="product-refinements" className={styles['product-refinements']}>
				{category && <CategoryList category={category} />}
				{category?.showFitModelSelection && availableSizes && (
					<SizeSelector
						className={styles['size-selector']}
						availableSizes={availableSizes}
						allSizes={allSizes}
						viewPreference={pageQueryParameters.viewPreference ?? undefined}
						selectId="sidebar-selector"
					/>
				)}
				{renderRefinementContent()}
			</div>

			<Dialog onClose={() => onCloseDialog?.()} isModalOpen={isDialogOpen} className={styles['refinements-dialog']}>
				<Dialog.Title className={styles['dialog-title']} data-testid="refinements-title">
					{formatMessage('refinements-title')}
				</Dialog.Title>
				<Dialog.Content className={styles['dialog-content']}>{renderRefinementContent(true)}</Dialog.Content>
				<Dialog.Actions>
					<ButtonLink
						as={LocaleLink}
						variant="secondary"
						className="clear-refinements-button"
						onClick={handleClearFilters}
						href={updateUrlParams(pathWithoutQuery, {
							q: pageQueryParameters.q || null,
						})}
					>
						{formatMessage(selectedRefinementValues.length ? 'clear-refinements-count' : 'clear-refinements', {
							count: selectedRefinementValues.length,
						})}
					</ButtonLink>
					<ButtonLink
						as={LocaleLink}
						onClick={() => {
							onCloseDialog?.()
							setRefinementChanges({})
						}}
						type="primary"
						aria-controls="mobile-filters-container"
						aria-expanded
						href={applyHref}
					>
						{isRefinementsLoading ? <LoadingDots /> : formatMessage('apply-refinements', { total: itemCount })}
					</ButtonLink>
				</Dialog.Actions>
			</Dialog>
		</>
	)
})
