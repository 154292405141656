import ColorChip from '~/components/shared/ColorChip'
import type { SearchRefinementAttributeValue } from '~/graphql/generated/uacapi/type-document-node'
import styles from './ColorRefinementValue.module.scss'

interface ColorRefinementValueProps {
	value: SearchRefinementAttributeValue
}
const ColorRefinementValue = ({ value }: ColorRefinementValueProps) => {
	return (
		<div className={styles['labeled-colorchip-container']}>
			<div data-selected={value.selected} className={styles['icon-container']}>
				<div className={styles.icon}>
					<ColorChip id={value.label} shape="square" primary={value.description} />
				</div>
			</div>
			<div className={styles['label-container']}>
				<div className={styles.label}>{value.label}</div>
			</div>
		</div>
	)
}

export default ColorRefinementValue
