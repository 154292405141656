import styles from './ProductGridSkeleton.module.scss'
import productGridStyles from './ProductGrid.module.scss'
import { ProductTileSkeleton } from '../ProductTileSkeleton/ProductTileSkeleton'

export function ProductGridSkeleton() {
	return (
		<section className={`${productGridStyles['product-list-container']}`}>
			<div className={styles['product-list-layout']}>
				<div className={styles['product-list-container']} data-testid="product-list-container">
					<div className={styles['product-list-grid']}>
						{Array(24)
							.fill(1)
							.map((id, index) => (
								<div key={`tile-skeleton-${index}`} className={styles['tile-skeleton-container']}>
									<ProductTileSkeleton key={`tile-${index}`} isPLP={true} />
								</div>
							))}
					</div>
				</div>
			</div>
		</section>
	)
}
