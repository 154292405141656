import styles from './ProductTileSkeleton.module.scss'
import { UaSkeleton } from '~/components/primitives/UaSkeleton/UaSkeleton'

export interface ProductTileSkeletonInterface {
	isPLP?: boolean
}

export function ProductTileSkeleton({ isPLP = false }: ProductTileSkeletonInterface) {
	return (
		<div data-testid="product-tile-container" className={styles['product-tile-skeleton']}>
			<div className={styles['product-skeleton-image-container']}>
				<UaSkeleton className={styles['product-image']} variant="rectangular" />
			</div>

			<div className={styles['product-tile-details']}>
				<UaSkeleton variant="rectangular" className={styles['product-tile-swatches']} cornerRadius={2} />
				{isPLP && <UaSkeleton variant="rectangular" className={styles['product-tile-badge']} cornerRadius={2} />}
				<UaSkeleton variant="rectangular" className={styles['product-tile-title']} cornerRadius={2} />
				<UaSkeleton variant="rectangular" className={styles['product-tile-price']} cornerRadius={2} />
			</div>
		</div>
	)
}
