import { forwardRef } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import Image from '~/components/primitives/Image'
import carrot from '~/public/carrot.svg?url'
import styles from './BackToTopButton.module.scss'

type BackToTopButtonProps = Omit<
	React.ComponentPropsWithRef<'button'>,
	'className' | 'type' | 'children' | 'aria-label' | 'aria-labelledby' | 'onClick'
>

const BackToTopButton = forwardRef<HTMLButtonElement, BackToTopButtonProps>(function BackToTopButton(attrs, ref) {
	const formatMessage = useFormatMessage()

	return (
		<button ref={ref} className={styles['up-carrot']} type="button" onClick={handleClick} {...attrs}>
			<span className="visually-hidden">{formatMessage('back-to-top')}</span>
			<Image src={carrot} alt={formatMessage('back-to-top')} fill unoptimized aria-hidden />
		</button>
	)
})

function handleClick() {
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

export default BackToTopButton
