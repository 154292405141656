'use client'

import { memo, useEffect, useMemo, useRef } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import Breadcrumbs, { type BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import Sorting from '~/components/shared/Sorting'
import type { Category, SearchRefinementAttribute } from '~/graphql/generated/uacapi/type-document-node'
import type { ClientProductList } from '~/lib/types/product.interface'
import styles from './BrowserHeader.module.scss'
import filterIcon from 'public/images/icons/plp/filters.svg?url'
import { Button } from '@ua-digital-commerce/ua-web-components/components/Button'
import Image from '~/components/primitives/Image'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import BrowserTitle from './BrowserTitle'
import { MOBILE_FILTER_BUTTON_ID } from '~/components/shared/ProductBrowser/ProductRefinements/ProductRefinements'
import { getSelectedRefinementValues } from '~/lib/products'
import RefinementBar from '../RefinementBar/RefinementBar'

interface ScrollRefinementsElements {
	headerHTMLElement: HTMLElement | null
	breadcrumbsHTMLElement: HTMLElement | null
	selectedFiltersHTMLElement: HTMLElement | null
}

export interface BrowserHeaderProps {
	productList: ClientProductList
	pathWithoutQuery: string
	refinementUrl: string
	queryParams: URLSearchParams
	category?: Category
	breadcrumbs: BreadcrumbTrail
	title: string
	handleToggleMobileNav: () => void
	onSortingUpdate: (sortBy: string) => void
	onUpdate?: () => void
}

// Threshold variables that control when styling actions occur
const SCROLL_POSITION_TO_COLLAPSE = 220
const SCROLL_POSITION_TO_EXPAND = 20
const SCROLL_DIRECTIONAL_CHANGE_OFFSET = 120

export const PLP_HEADER_ID = 'plp-browser-header'
const PLP_BREADCRUMBS_ID = 'plp-breadcrumbs'
const PLP_ACTIVE_FILTERS_ID = 'plp-selected-filters'

export default memo(function BrowserHeader({
	productList,
	pathWithoutQuery,
	refinementUrl,
	queryParams,
	category,
	breadcrumbs,
	title,
	handleToggleMobileNav,
	onSortingUpdate,
	onUpdate,
}: BrowserHeaderProps) {
	const formatMessage = useFormatMessage()
	const { isMobile } = useLayoutMeasurement()
	const sortRule = useMemo(() => queryParams.get('srule') ?? null, [queryParams])

	// This value is similar but distinct from the populated refinements variable in
	// the `ProductRefinements` component. This component only uses refinements populated via
	// the url, whereas `ProductRefinements` keeps state from user actions.
	const populatedRefinements: SearchRefinementAttribute[] = useMemo(
		() =>
			productList?.refinementAttributes.filter((refinement) => refinement.values.filter((value) => value.hitCount > 0)),
		[productList],
	)
	const selectedRefinementValues = useMemo(
		() => getSelectedRefinementValues(populatedRefinements),
		[populatedRefinements],
	)

	const numberOfFiltersSelected = useMemo(
		() => (selectedRefinementValues ? selectedRefinementValues.length : 0),
		[selectedRefinementValues],
	)

	const mobileScrollPositionRef = useRef<number>(0)
	const showPeekMobileHeaderRef = useRef<boolean>(false)
	const mobileScrollElementsRef = useRef<ScrollRefinementsElements>({
		headerHTMLElement: null,
		breadcrumbsHTMLElement: null,
		selectedFiltersHTMLElement: null,
	})

	/**
	 * Handler for Collapsing/Expanding PLP Header for mobile views.
	 * @param collapse
	 */
	const handleMobileHeaderCollapse = (shouldCollapse: boolean): void => {
		const { headerHTMLElement, breadcrumbsHTMLElement, selectedFiltersHTMLElement } = mobileScrollElementsRef.current

		// Apply attributes to HTML elements for smooth styling
		const isShowingPeekOfHeader = showPeekMobileHeaderRef.current
		breadcrumbsHTMLElement?.toggleAttribute('data-collapsed', shouldCollapse)
		selectedFiltersHTMLElement?.toggleAttribute('data-collapsed', shouldCollapse && !isShowingPeekOfHeader)

		headerHTMLElement?.classList.toggle(styles.collapsed, shouldCollapse)
	}

	/**
	 * Handler for attaching 'peek' styles to header element for mobile views.
	 * @param collapse
	 */
	const handleMobileHeaderPeek = (showPeekStyling: boolean): void => {
		const { headerHTMLElement, selectedFiltersHTMLElement } = mobileScrollElementsRef.current
		showPeekMobileHeaderRef.current = showPeekStyling
		headerHTMLElement?.classList.toggle(styles.peek, showPeekStyling)
		selectedFiltersHTMLElement?.toggleAttribute('data-collapsed', false)
	}

	/**
	 * Scroll event handler to monitor and apply scrolling animations.
	 */
	const handleWindowScroll = (): void => {
		// The below prevents unintented scrolling animations when dialogs are showing
		// The base dialog component manipulates body top property which kicked off the animations below
		if (document.body.style.overflow === 'hidden') {
			return
		}
		const { headerHTMLElement } = mobileScrollElementsRef.current
		const lastScrollPosition = mobileScrollPositionRef.current
		const showPeekOfFullHeader = showPeekMobileHeaderRef.current

		const currentScroll = window?.scrollY
		const isScrollUpwards = currentScroll < lastScrollPosition

		if (headerHTMLElement) {
			// Collapse Header when user has started scrolling page
			if (currentScroll >= SCROLL_POSITION_TO_COLLAPSE) {
				handleMobileHeaderCollapse(true)

				// When scrolling upwards, toggle the `peek` styling when user has scrolled past offset threshold
				if (
					isScrollUpwards &&
					!showPeekOfFullHeader &&
					currentScroll + SCROLL_DIRECTIONAL_CHANGE_OFFSET <= lastScrollPosition
				) {
					handleMobileHeaderPeek(true)
					// When scrolling downwards, remove the `peek` styling when user has scrolled past offset threshold
				} else if (
					!isScrollUpwards &&
					showPeekOfFullHeader &&
					currentScroll >= lastScrollPosition + SCROLL_DIRECTIONAL_CHANGE_OFFSET
				) {
					handleMobileHeaderPeek(false)
				}

				// This updates scroll position for directional analysis.
				// We only want to track user's scroll position in one direction so we can sufficiently determine
				// when to show directional change styling. As such, we ignore incremental changes in direction.
				if ((isScrollUpwards && showPeekOfFullHeader) || (!isScrollUpwards && !showPeekOfFullHeader)) {
					mobileScrollPositionRef.current = currentScroll
				}
			} else if (currentScroll < SCROLL_POSITION_TO_EXPAND) {
				// Show full mobile header when at the top of the page
				handleMobileHeaderCollapse(false)
				handleMobileHeaderPeek(false)
			}
		}
	}

	useEffect(() => {
		// Capture needed HTML Elements
		mobileScrollElementsRef.current = {
			headerHTMLElement: document.getElementById(PLP_HEADER_ID) ?? null,
			breadcrumbsHTMLElement: document.getElementById(PLP_BREADCRUMBS_ID) ?? null,
			selectedFiltersHTMLElement: document.getElementById(PLP_ACTIVE_FILTERS_ID) ?? null,
		}
		const mobileNavCloseButton = document.getElementById(MOBILE_FILTER_BUTTON_ID) as HTMLElement

		if (isMobile) {
			// Scroll Listener
			document.addEventListener('scroll', handleWindowScroll)

			// Close Mobile Menu Listener
			mobileNavCloseButton.addEventListener('click', handleToggleMobileNav)
		}

		return () => {
			document.removeEventListener('scroll', handleWindowScroll)
			mobileNavCloseButton?.removeEventListener('click', handleToggleMobileNav)

			// When toggling the mobile menu open/closed, we set the overflow on the document
			// body to be hidden/visible in handleToggle() below. This is never removed when clicking
			// links. This allows us to remove that property and allow scrolling when the new page
			// loads if a user selects a link/filter from that mobile menu.
			// ** This should be considered a temporary fix until we can find a better solution **
			document.body.style.removeProperty('overflow')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile])

	return (
		<div className={styles.header} id={PLP_HEADER_ID}>
			<Breadcrumbs
				id={PLP_BREADCRUMBS_ID}
				currentPath={pathWithoutQuery}
				className={styles.breadcrumbs}
				trail={breadcrumbs}
				getCurrentCrumbBy="position"
				lastWithoutLink
			/>

			{!isMobile && <BrowserTitle className={styles.title} title={title} isCategory={!!category} />}

			{productList && (
				<div className={styles.actionBar}>
					{!!numberOfFiltersSelected && (
						<RefinementBar
							id={PLP_ACTIVE_FILTERS_ID}
							className={styles['selected-filters']}
							allRefinements={productList.refinementAttributes}
							selectedRefinements={selectedRefinementValues}
							refinementUrl={refinementUrl}
							queryParams={queryParams}
							category={category}
							onUpdate={onUpdate}
						/>
					)}

					<div className={styles.sorting} id="plp-sorting-container">
						{/* Title is duplicated for styling on Mobile Screens */}
						{isMobile && <BrowserTitle className={styles['mobile-title']} title={title} isCategory={!!category} />}

						<p className="text-body">{formatMessage('your-cart-count', { totals: productList.totalCount })}</p>

						<Sorting
							className={styles['product-sort']}
							sortOptions={productList.sortingOptions}
							defaultSortRule={sortRule ?? ''}
							onSortingUpdate={onSortingUpdate}
						/>

						<Button
							className={styles['mobile-filter-sort-button']}
							type="button"
							variant="text"
							onClick={handleToggleMobileNav}
						>
							{formatMessage('filter-sort')}
							{!!numberOfFiltersSelected && (
								<span className={styles['selected-filters-count']}>{`(${numberOfFiltersSelected})`}</span>
							)}
							<Image src={filterIcon} width={24} height={24} alt="" />
						</Button>
					</div>
				</div>
			)}
		</div>
	)
})
