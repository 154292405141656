import type { SearchRefinementAttributeValue } from '~/graphql/generated/uacapi/type-document-node'

interface PriceRefinementValueProps {
	value: SearchRefinementAttributeValue
}

const PriceRefinementValue = ({ value }: PriceRefinementValueProps) => {
	return (
		<span>
			{value.label} ({value.hitCount})
		</span>
	)
}

export default PriceRefinementValue
