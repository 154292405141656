import { useMemo } from 'react'
import FilterChip from '../FilterChip/FilterChip'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useLocale } from '~/components/hooks/useLocale'
import { createRefinementUrl, getRefinementKey } from '~/lib/routes'
import { ensureString } from '~/types/strict-null-helpers'
import { updateUrlParams } from '~/lib/utils'
import type { Category, SearchRefinementAttribute } from '~/graphql/generated/uacapi/type-document-node'
import type {
	CioSearchRefinementAttribute,
	SearchRefinementAttributeValueWithAttributeId,
} from '~/lib/types/product.interface'
import clsx from 'clsx'
import styles from './RefinementBar.module.scss'
import { getRefinementCategoryIdByLabel, getFormattedLabel } from '~/lib/products'
import { FACET_MAP } from '~/lib/search'

interface RefinementBarProps {
	id?: string
	className?: string
	allRefinements: SearchRefinementAttribute[] | CioSearchRefinementAttribute[]
	selectedRefinements: SearchRefinementAttributeValueWithAttributeId[]
	refinementUrl: string
	queryParams: URLSearchParams
	category?: Category
	showClear?: boolean
	onUpdate?: (e?: React.SyntheticEvent<HTMLElement>, refinement?: SearchRefinementAttributeValueWithAttributeId) => void
	onClear?: () => void
}

function RefinementBar({
	id,
	className,
	allRefinements,
	selectedRefinements,
	refinementUrl,
	queryParams,
	category,
	showClear = true,
	onUpdate,
	onClear,
}: RefinementBarProps) {
	const formatMessage = useFormatMessage()
	const locale = useLocale()

	const { searchTerm, sortRule } = useMemo(
		() => ({
			searchTerm: queryParams.get('q') ?? null,
			sortRule: queryParams.get('srule') ?? null,
		}),
		[queryParams],
	)

	const handleClearRefinements = () => {
		onUpdate?.()
		onClear?.()
	}

	return (
		<ul className={clsx(className, styles['filter-bar'])} id={id}>
			{selectedRefinements.map((value) => {
				const isTeamRefinement = getRefinementCategoryIdByLabel(allRefinements, value.label) === FACET_MAP.team.uacapiId
				const label = getFormattedLabel(formatMessage, value)

				return (
					<li className={styles.chipContainer} key={`applied-filter: ${value.label}`}>
						<FilterChip
							logoUrl={isTeamRefinement ? value.description : undefined}
							label={label}
							title={formatMessage('remove-filter', { filter: label })}
							onClick={(e) => onUpdate?.(e, value)}
							href={createRefinementUrl({
								refinements: allRefinements,
								url: refinementUrl,
								categoryId: ensureString(category?.id),
								updatedRefinements: { [getRefinementKey(value.attributeId, value.label)]: !value.selected },
								locale,
								currentQueryParams: queryParams.toString(),
							})}
						/>
					</li>
				)
			})}

			{showClear && (
				<li className={styles.chipContainer}>
					<FilterChip
						label={formatMessage('clear-refinements')}
						href={updateUrlParams(refinementUrl, {
							q: searchTerm,
							srule: sortRule,
						})}
						showIcon={false}
						onClick={handleClearRefinements}
					/>
				</li>
			)}
		</ul>
	)
}

export default RefinementBar
