import type { Category } from '~/graphql/generated/uacapi/type-document-node'

import { removeTrailingSlash } from '~/lib/utils'
import { useFormatMessage } from '../hooks/useFormatMessage'
import { useLocale } from '../hooks/useLocale'
import styles from './Breadcrumbs.module.scss'
import { LocaleLink, type LocaleLinkProps } from '~/components/primitives/LocaleLink/LocaleLink'
import { Fragment } from 'react'
import { getUrlWithoutLocale } from '~/lib/i18n/urls'
import { ensureString } from '~/types/strict-null-helpers'
import ConditionalWrapper from './ConditionalWrapper'

type Breadcrumb = Pick<Category, 'name' | 'url' | 'hideFromBreadCrumbs'> & Partial<Pick<Category, 'id'>>
export type BreadcrumbTrail = Breadcrumb[]

interface BreadcrumbsProps {
	id?: string
	currentPath: string
	className?: string
	trail?: Breadcrumb[]
	lastWithoutLink?: boolean
	/** The means by which the currently active breadcrumb will be determined */
	getCurrentCrumbBy?: 'url' | 'position'
}

const BreadcrumbWrapper = ({ children, href, ...rest }: React.PropsWithChildren & LocaleLinkProps) => (
	<LocaleLink href={href} {...rest}>
		{children}
	</LocaleLink>
)

function Breadcrumbs({
	id,
	className = '',
	trail = [],
	getCurrentCrumbBy,
	lastWithoutLink = false,
	currentPath,
}: BreadcrumbsProps) {
	const locale = useLocale()
	const formatMessage = useFormatMessage()

	// Only show breadcrumbs that link to a specific category PLP
	const breadcrumbTrail = trail.filter((category) => !category.hideFromBreadCrumbs && !!category.id)

	const jsonLD = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: breadcrumbTrail.map(({ name, url }, idx) => ({
			'@type': 'ListItem',
			position: idx + 1,
			name,
			item: `https://www.underarmour.com/${locale}${getUrlWithoutLocale(ensureString(url))}`, // TODO: Internationalize `hostname` as well
		})),
	}

	return (
		<>
			<script
				id="ld_json_breadcrumbList"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD, null, 2) }}
			/>
			<nav id={id} className={`${styles.breadcrumbs} ${className}`.trim()} aria-label={formatMessage('breadcrumb')}>
				<ol>
					{breadcrumbTrail.map(({ name, url }, i) => {
						const isLastElement = i === breadcrumbTrail.length - 1

						let ariaCurrent: 'page' | undefined
						if (
							(getCurrentCrumbBy === 'url' &&
								removeTrailingSlash(currentPath) === removeTrailingSlash(`/${locale}${url}`)) ||
							(getCurrentCrumbBy === 'position' && isLastElement)
						) {
							ariaCurrent = 'page'
						}

						const showBreadcrumbAsText = isLastElement && lastWithoutLink
						// 'ariaCurrent' should be attached to the <a> element, if present, otherwise the <li>.
						// This conditionally assigns that value so the jsx is easier to read below.
						const [listAria, linkAria] = showBreadcrumbAsText ? [ariaCurrent, undefined] : [undefined, ariaCurrent]

						return (
							<Fragment key={name}>
								<li aria-current={listAria}>
									<ConditionalWrapper
										condition={!showBreadcrumbAsText}
										as={BreadcrumbWrapper}
										href={ensureString(url)}
										aria-current={linkAria}
									>
										{name}
									</ConditionalWrapper>
								</li>
							</Fragment>
						)
					})}
				</ol>
			</nav>
		</>
	)
}

export default Breadcrumbs
